import { useSelector } from 'react-redux';
import Header from '../question-header/QuestionHeader';
import Text from '../question-text/QuestionText';
import Dropdown from '../question-dropdown/QuestionDropdown';
import DateQuestion from '../question-date/QuestionDate';
import DateTimeQuestion from '../question-date-time/QuestionDateTime';
import Checkbox from '../question-checkbox/QuestionCheckbox';
import QuestionContainer from '../question-container/QuestionContainer';
import { Question } from '../../models/Question';
import { FileAnswerUploadDTO, FileParameter, QuestionType, UpdateAnswerDTO } from '../../api/api';
import QAService from '../../services/QAService';
import NotificationService from '../../services/NotificationService';
import { log } from '../../utils/logging/log';
import useQuery from '../../hooks/useQuery';
import { QueryParams } from '../../models/QueryParams';
import QuestionFile from '../question-file/QuestionFile';
import TaskService from '../../services/TaskService';
import { selectUserProfile } from '../../stateManagement/reducers/userProfileReducer';
import { selectTaskChecklistState } from '../../stateManagement/reducers/taskChecklistReducer';
import TextRow from '../../views/task-view/task-details-view2/components/TextRow';
import styled from 'styled-components';
import { formatDateStringISO, formatTimeAndDateString } from '../../utils/dateHandling';
import AuditInfo from '../audit-info/AuditInfo';

export interface QuestionProps {
  question: Question;
  handleSave?: (answer: string | number | undefined) => void;
  handleSaveFile?: (file: File, answerId: number, callback?: () => void) => void;
  required?: boolean;
  showMandatoryFieldError?: boolean;
}

interface Props {
  question: Question;
  handleSaveValue: (answer: UpdateAnswerDTO) => void;
  handleSaveValueFiles: (answer: FileAnswerUploadDTO) => void;
  handleDeleteFile: (fileAnswerId: number, questionId: number, taskId: number) => void;
  readOnly?: boolean;
}

const QuestionWrapper = (props: Props) => {
  const { question, handleSaveValue, handleSaveValueFiles, handleDeleteFile, readOnly } = props;
  const { id } = useQuery<QueryParams>();
  const taskId = parseInt(id ?? '-1');
  const userName = useSelector(selectUserProfile).userProfile.name;
  const showChecklistErrors = useSelector(selectTaskChecklistState).showChecklistErrors.showError;

  const handleOnSaveText = (value: string | number | undefined) => {
    handleSaveValue({
      answerId: question.id,
      answerTextValue: value?.toString(),
      sectionQuestionId: question.sectionQuestionId,
      workTaskId: taskId
    });
  };

  const handleOnSaveInt = (value: string | number | undefined) => {
    handleSaveValue({
      answerId: question.id,
      answerIntValue: typeof value === 'number' ? value : value === undefined ? undefined : parseInt(value),
      sectionQuestionId: question.sectionQuestionId,
      workTaskId: taskId
    });
  };

  const handleOnSaveDouble = (value: string | number | undefined) => {
    handleSaveValue({
      answerId: question.id,
      answerDoubleValue: typeof value === 'number' ? value : value === undefined ? undefined : parseFloat(value),
      sectionQuestionId: question.sectionQuestionId,
      workTaskId: taskId
    });
  };

  const handleOnSaveFile = (files: FileParameter[], answerId: number, callback?: () => void) => {
    if (answerId !== -1) {
      QAService.PostQAFiles(taskId, answerId, files)
        .then(() => {
          const newAnswer = {
            answerId: question.id ?? answerId,
            answerTimeStampValue: new Date().toISOString(),
            files: files.map((file) => {
              return { fileName: file.fileName, uploadedBy: userName };
            }),
            sectionQuestionId: question.sectionQuestionId,
            workTaskId: taskId
          } as FileAnswerUploadDTO;

          TaskService.addFileAnswers(newAnswer).then((data) => {
            newAnswer.files = data;
            handleSaveValueFiles(newAnswer);
          });
          NotificationService.success('Filerne er uploadet');
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Der opstod en fejl ved upload af filerne - filerne er ikke uploadet');
        })
        .finally(() => {
          callback && callback();
        });
    }
  };

  const getQuestionValue = (question: Question) => {
    switch (question.dataType) {
      case QuestionType.Text:
      case QuestionType.Number:
        return question.value;
      case QuestionType.Date:
        return question.value ? formatDateStringISO(question.value, 'ddmmyyyy') : '';
      case QuestionType.DateTime:
        return question.value ? formatTimeAndDateString(question.value) : '';
      case QuestionType.SingleChoice:
        return question.options?.find((o) => o.value === question.value)?.displayValue;
      case QuestionType.Confirm:
        return question.value === 1 ? 'Bekræftet' : 'Ikke bekræftet';
      default:
        return question.value;
    }
  };

  if (readOnly) {
    switch (question.dataType) {
      case QuestionType.Header:
        return <Header text={question.text} />;
      case QuestionType.Text:
      case QuestionType.Number:
      case QuestionType.SingleChoice:
      case QuestionType.Date:
      case QuestionType.DateTime:
      case QuestionType.Confirm:
        return (
          <QuestionContainer
            input={
              <StyledTextRow>
                <TextRow
                  inputMode={false}
                  label={question.text}
                  value={getQuestionValue(question)}
                  required={question.mandatory}
                />
                <AuditInfo
                  updatedByEmail={question.updatedByEmail}
                  updatedTimeStamp={question.timeStampValue}
                  size={'24px'}
                />
              </StyledTextRow>
            }
          />
        );
      case QuestionType.Image:
        return (
          <QuestionContainer
            input={
              <StyledTextRow>
                <QuestionFile
                  question={question}
                  required={question.mandatory}
                  showMandatoryFieldError={showChecklistErrors}
                  taskId={taskId}
                  handleSaveFile={handleOnSaveFile}
                  handleRemove={handleDeleteFile}
                  readOnly={readOnly}
                />
              </StyledTextRow>
            }
          />
        );
      default:
        log('Could not determine question type.');
        return <></>;
    }
  }

  switch (question.dataType) {
    case QuestionType.Header:
      return <QuestionContainer link={question.link} linkText={question.linkText} input={<Header text={question.text} />} />;

    case QuestionType.Text:
      return (
        <QuestionContainer
          link={question.link}
          linkText={question.linkText}
          input={
            <Text
              type="text"
              handleSave={handleOnSaveText}
              question={question}
              required={question.mandatory}
              showMandatoryFieldError={showChecklistErrors}
            />
          }
        />
      );
    case QuestionType.Number:
      return (
        <QuestionContainer
          link={question.link}
          linkText={question.linkText}
          input={
            <Text
              type="number"
              handleSave={handleOnSaveDouble}
              question={question}
              required={question.mandatory}
              showMandatoryFieldError={showChecklistErrors}
            />
          }
        />
      );
    case QuestionType.SingleChoice:
      return (
        <QuestionContainer
          link={question.link}
          linkText={question.linkText}
          input={
            <Dropdown
              handleSave={handleOnSaveInt}
              question={question}
              required={question.mandatory}
              showMandatoryFieldError={showChecklistErrors}
            />
          }
        />
      );
    case QuestionType.Date:
      return (
        <QuestionContainer
          link={question.link}
          linkText={question.linkText}
          input={
            <DateQuestion
              handleSave={handleOnSaveText}
              question={question}
              required={question.mandatory}
              showMandatoryFieldError={showChecklistErrors}
            />
          }
        />
      );
    case QuestionType.DateTime:
      return (
        <QuestionContainer
          link={question.link}
          linkText={question.linkText}
          input={
            <DateTimeQuestion
              handleSave={handleOnSaveText}
              question={question}
              required={question.mandatory}
              showMandatoryFieldError={showChecklistErrors}
            />
          }
        />
      );
    case QuestionType.Confirm:
      return (
        <QuestionContainer
          link={question.link}
          linkText={question.linkText}
          input={
            <Checkbox
              handleSave={handleOnSaveInt}
              question={question}
              required={question.mandatory}
              showMandatoryFieldError={showChecklistErrors}
            />
          }
        />
      );
    case QuestionType.Image:
      return (
        <QuestionContainer
          link={question.link}
          linkText={question.linkText}
          input={
            <QuestionFile
              question={question}
              required={question.mandatory}
              showMandatoryFieldError={showChecklistErrors}
              taskId={taskId}
              handleSaveFile={handleOnSaveFile}
              handleRemove={handleDeleteFile}
            />
          }
        />
      );
    default:
      log('Could not determine question type.');
      return <></>;
  }
};

const StyledTextRow = styled.div`
  display: flex;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

export default QuestionWrapper;
