import styled from 'styled-components';
import { Row, Section, SectionHeader } from '../../../../../styling/FormStyling';
import TextRow from '../../components/TextRow';
import { StyledSectionContent } from '../TaskDetailsStep';
import { WorkTaskDTOExtended } from '../../../../../models/TaskType';
import { convertHoursAndMinutesToMinutes, convertMinutesToHoursAndMinutes } from '../../../../../utils/timeHandling';
import SimpleTime from '../../../../../components/simple-time/SimpleTime';
import DepartmentDropdown from '../../../../../components/department-dropdown/DepartmentDropdown';
import { CloseIcon } from '../../../../../assets/icons/CloseIcon';
import ProjectNumberDropdown, {
  MappedProjectNumber
} from '../../../../../components/project-number-dropdown/ProjectNumberDropdown';
import { CheckmarkIcon } from '../../../../../assets/icons/CheckmarkIcon';
import IconButton from '../../../../../components/icon-button/IconButton';
import { CustomerAppointmentDTO, DepartmentDTO, ProjectDTO2 } from '../../../../../api/api';
import { EditIcon } from '../../../../../assets/icons/EditIcon';
import { ColumnFlex3 } from '../../../../../styling/Styling';
import { dateStringsAreEqual } from '../../../../../utils/dateHandling';

interface Props {
  editable?: boolean;
  task?: WorkTaskDTOExtended;
  customerAppointment?: CustomerAppointmentDTO;
  saveNewProjectNumber: () => void;
  cancelChanges: () => void;
  workInvoice?: number;
  isProjectNumberIsEditable: boolean;
  projectNumberObject?: MappedProjectNumber;
  workInvoiceProjectNumber?: string;
  projectNumberAndName: string;
  setIsProjectNumberEditable: (val: boolean) => void;
  isLoadingProjectNumbers: boolean;
  selectedDepartment?: DepartmentDTO;
  externalProjects: ProjectDTO2[];
  isDirty: boolean;
  toggleFieldIsDirty: (key: string, value: boolean) => void;
  setSelectedDepartment: (department: DepartmentDTO) => void;
  setEarliestStartDate: (date: string) => void;
  setDeadline: (date: string) => void;
  setRemainingTime: (remainingTime: number) => void;
  remainingTime?: number;
  setProjectNumberObject: (projectNumberObject: MappedProjectNumber) => void;
}

export const Details = (props: Props) => {
  const {
    editable,
    task,
    customerAppointment,
    saveNewProjectNumber,
    cancelChanges,
    workInvoice,
    isProjectNumberIsEditable,
    projectNumberObject,
    workInvoiceProjectNumber,
    projectNumberAndName,
    setIsProjectNumberEditable,
    isLoadingProjectNumbers,
    selectedDepartment,
    externalProjects,
    isDirty,
    setSelectedDepartment,
    toggleFieldIsDirty,
    setEarliestStartDate,
    setDeadline,
    setRemainingTime,
    remainingTime,
    setProjectNumberObject
  } = props;

  const getTimeText = (hours: number, minutes: number) => {
    const hoursWord = hours === 1 ? 'time' : 'timer';
    const minuteWord = minutes === 1 ? 'minut' : 'minutter';

    if (hours === 0) return `${minutes} ${minuteWord}`;
    if (minutes === 0) return `${hours} ${hoursWord}`;

    return `${hours} ${hoursWord} og ${minutes} ${minuteWord}`;
  };

  const handleStartDateChange = (date: string | undefined) => {
    if (!!date) {
      const dateAsDate = new Date(date);
      const earliestStartDateAsDate = new Date(task?.earliestStartDate ?? '');

      setEarliestStartDate(date);
      toggleFieldIsDirty('earliestStartDate', dateAsDate.toString() !== earliestStartDateAsDate.toString());
    }
  };

  const handleDeadlineChange = (date: string | undefined) => {
    if (!!date && task?.deadline) {
      setDeadline(date);
      toggleFieldIsDirty('deadlineDate', !dateStringsAreEqual(date, task?.deadline));
    }
  };

  const handleRemainingTimeChange = (minutes: string | undefined) => {
    const parsedMinutes = minutes ? parseInt(minutes) : 0;
    setRemainingTime(parsedMinutes);
    toggleFieldIsDirty('remainingTimeInMinutes', parsedMinutes !== remainingTime);
  };

  const handleChangeDepartment = (department: DepartmentDTO) => {
    toggleFieldIsDirty('department', department !== task?.assignedToDepartment);
    setSelectedDepartment(department);
  };

  const handleProjectNumberChange = (value: MappedProjectNumber) => {
    if (!value) return;
    setProjectNumberObject(value);
    toggleFieldIsDirty('projectNumber', task?.projectNumber !== value?.value.id);
  };

  return (
    <Section>
      <SectionHeader>Detaljer</SectionHeader>
      <StyledSectionContent>
        <StyledColumnFlex>
          {!editable ? (
            <TextRow
              type="number"
              label="Normtid"
              value={
                task?.normTimeMin
                  ? getTimeText(
                      convertMinutesToHoursAndMinutes(task?.normTimeMin).hours,
                      convertMinutesToHoursAndMinutes(task?.normTimeMin).minutes
                    )
                  : '-'
              }
            />
          ) : (
            <SimpleTime
              label="Normtid"
              initialHours={convertMinutesToHoursAndMinutes(task?.normTimeMin ?? 0).hours}
              initialMinutes={convertMinutesToHoursAndMinutes(task?.normTimeMin ?? 0).minutes}
              disabled
            />
          )}

          {!editable ? (
            <TextRow
              type="number"
              label="Resterende tid"
              onChange={handleRemainingTimeChange}
              value={
                task?.remainingTimeMin
                  ? getTimeText(
                      convertMinutesToHoursAndMinutes(task?.remainingTimeMin).hours,
                      convertMinutesToHoursAndMinutes(task?.remainingTimeMin).minutes
                    )
                  : '-'
              }
            />
          ) : (
            <SimpleTime
              label="Resterende tid"
              initialHours={convertMinutesToHoursAndMinutes(task?.remainingTimeMin ?? 0).hours}
              initialMinutes={convertMinutesToHoursAndMinutes(task?.remainingTimeMin ?? 0).minutes}
              onTimeChange={(hours, minutes) =>
                handleRemainingTimeChange(convertHoursAndMinutesToMinutes(hours, minutes).toString())
              }
            />
          )}
          <TextRow
            inputMode={editable}
            type="date"
            label="Startdato"
            value={task?.earliestStartDate ?? ''}
            onChange={handleStartDateChange}
            disabled={!!customerAppointment}
          />
          <TextRow
            inputMode={editable}
            type="date"
            label="Slutdato"
            value={task?.deadline ?? ''}
            onChange={handleDeadlineChange}
            disabled={!!customerAppointment}
          />
          <TextRow
            inputMode={editable}
            disabled
            label="Spænding"
            value={task?.primaryVoltage ? task?.primaryVoltage / 1000 + ' kV' : '-'}
          />
          {editable ? (
            <DepartmentDropdown selectDepartment={handleChangeDepartment} value={selectedDepartment?.departmentId} />
          ) : (
            <TextRow label="Send til" value={task?.assignedToDepartment?.name ? task?.assignedToDepartment.name : '-'} />
          )}

          <Row>
            {(editable || isProjectNumberIsEditable) && !isLoadingProjectNumbers && (
              <>
                <ProjectNumberDropdown
                  value={projectNumberObject}
                  projectNumberChange={(value: any) => handleProjectNumberChange(value)}
                  options={externalProjects}
                  isLoadingProjectNumbers={isLoadingProjectNumbers}
                  disableClearable
                />
                {isProjectNumberIsEditable && !editable && (
                  <ButtonContainer>
                    <StyledIconButton onClick={saveNewProjectNumber} disabled={!isDirty}>
                      <CheckmarkIcon size="18px" />
                    </StyledIconButton>
                    <StyledIconButton onClick={cancelChanges}>
                      <CloseIcon size="16px" />
                    </StyledIconButton>
                  </ButtonContainer>
                )}
              </>
            )}
            {!isProjectNumberIsEditable && !editable && (
              <ProjectNumberContainer>
                <TextRow
                  inputMode={editable}
                  disabled
                  label={workInvoice ? 'Oprindeligt projektnummer' : 'Projektnummer'}
                  value={projectNumberAndName}
                />
                {!workInvoice && (
                  <StyledIconButton onClick={() => setIsProjectNumberEditable(true)}>
                    <EditIcon size="20px" />
                  </StyledIconButton>
                )}
              </ProjectNumberContainer>
            )}
          </Row>
          {workInvoice && (
            <Row>
              <TextRow
                inputMode={false}
                disabled
                label={'Skadevolder/regningsarbejde projektnummer'}
                value={workInvoiceProjectNumber ?? '-'}
              />
            </Row>
          )}
        </StyledColumnFlex>
      </StyledSectionContent>
    </Section>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  padding: 10px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 10px 10px;
`;

const ProjectNumberContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledColumnFlex = styled(ColumnFlex3)`
  justify-content: space-between;
  width: 50%;
`;
