import { CustomerAppointmentDTO } from '../../../../../api/api';
import TimeOfDayDropdown from '../../../../../components/time-of-day-dropdown/TimeOfDayDropdown';
import { Section, SectionHeader } from '../../../../../styling/FormStyling';
import { ColumnFlex3 } from '../../../../../styling/Styling';
import { getEnumDisplayValue } from '../../../../../utils/enumUtils';
import TextRow from '../../components/TextRow';
import { AppointmentErrors, StyledSectionContent } from '../TaskDetailsStep';

interface Props {
  editable?: boolean;
  customerAppointment?: CustomerAppointmentDTO;
  appointmentErrors: AppointmentErrors;
  setCustomerAppointment: (appointment: CustomerAppointmentDTO) => void;
  toggleFieldIsDirty: (key: string, value: boolean) => void;
}
export const CustomerAppointment = (props: Props) => {
  const { editable, customerAppointment, appointmentErrors, setCustomerAppointment, toggleFieldIsDirty } = props;

  const handleChangeCustomerAppointmentField = (key: keyof CustomerAppointmentDTO, value: string | boolean) => {
    if (!props.customerAppointment) return;

    setCustomerAppointment(customerAppointment ? { ...customerAppointment, [key]: value } : { [key]: value });

    toggleFieldIsDirty(key, value !== props.customerAppointment[key]);
  };
  return (
    <>
      <Section>
        <SectionHeader>Kundeaftale</SectionHeader>
        <StyledSectionContent>
          <ColumnFlex3>
            <h4>Kontaktoplysninger</h4>
            <TextRow
              inputMode={editable}
              label="Kontaktperson"
              value={customerAppointment?.customerName ?? ''}
              onChange={(value) => handleChangeCustomerAppointmentField('customerName', value)}
              error={appointmentErrors.customerName}
              required
            />
            <TextRow
              inputMode={editable}
              label="Kontaktpersons tlf. nr."
              value={customerAppointment?.customerPhoneNumber ?? ''}
              onChange={(value) => handleChangeCustomerAppointmentField('customerPhoneNumber', value)}
              required
            />
            <TextRow
              inputMode={editable}
              label="Email"
              value={customerAppointment?.customerEmail ?? ''}
              onChange={(value) => handleChangeCustomerAppointmentField('customerEmail', value)}
            />
            <TextRow
              inputMode={editable}
              type="multilineText"
              label="Bemærkninger"
              value={customerAppointment?.customerRemarks ?? ''}
              onChange={(value) => handleChangeCustomerAppointmentField('customerRemarks', value)}
            />
          </ColumnFlex3>
          {(customerAppointment?.startDate || customerAppointment?.endDate || customerAppointment?.preferredTimeOfDay) && (
            <ColumnFlex3>
              <h4>Ønske til tidsaftalen</h4>
              <TextRow
                inputMode={editable}
                required
                type="date"
                label="Start dato"
                value={customerAppointment?.startDate ?? ''}
                onChange={(value) => handleChangeCustomerAppointmentField('startDate', value)}
              />
              <TextRow
                inputMode={editable}
                required
                type="date"
                label="Slut dato"
                value={customerAppointment?.endDate ?? ''}
                onChange={(value) => handleChangeCustomerAppointmentField('endDate', value)}
              />
              {customerAppointment?.preferredTimeOfDay && editable ? (
                <TimeOfDayDropdown
                  required
                  label={'Ønsket tidsrum på dagen'}
                  value={customerAppointment.preferredTimeOfDay}
                  onChange={(e) => handleChangeCustomerAppointmentField('preferredTimeOfDay', e.target.value)}
                />
              ) : (
                customerAppointment?.preferredTimeOfDay && (
                  <TextRow
                    inputMode={editable}
                    label="Ønsket tidsrum på dagen"
                    value={getEnumDisplayValue(customerAppointment?.preferredTimeOfDay)}
                    required
                  />
                )
              )}
              <TextRow
                inputMode={editable}
                onChange={(e) => {
                  handleChangeCustomerAppointmentField('approvedByPlanning', e === 'true');
                }}
                type="checkbox"
                label="Tidsaftale godkendt af planlægning"
                value={customerAppointment?.approvedByPlanning?.toString() ?? ''}
              />
            </ColumnFlex3>
          )}
        </StyledSectionContent>
      </Section>
    </>
  );
};
