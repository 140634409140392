import { useEffect, useState } from 'react';
import DialogView from '../../../components/dialog-view/DialogView';
import TextField from '../../../components/text-field/TextField';
import Button from '../../../components/button/Button';
import styled from 'styled-components';
import { DialogContent } from '@mui/material';
import Typography from '../../../components/typography/Typography';
import IconButton from '../../../components/icon-button/IconButton';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import { CreatePortalLinkDto, PortalLinkDto } from '../../../api/api';
import UserService from '../../../services/UserService';
import NotificationService from '../../../services/NotificationService';
import { log } from '../../../utils/logging/log';

interface Props {
  open: boolean;
  closeDialog: () => void;
  createPortalLinkCallback: (portalLink: CreatePortalLinkDto) => void;
  editPortalLinkCallback: (portalLink: PortalLinkDto) => void;
  allPortalLinks: PortalLinkDto[];
  editingPortalLink?: PortalLinkDto;
}

const CreateEditPortalLinkForm = (props: Props) => {
  const { open, closeDialog, createPortalLinkCallback, editPortalLinkCallback, allPortalLinks, editingPortalLink } = props;
  const [linkText, setLinkText] = useState('');
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    setLink(editingPortalLink?.link ?? '');
    setLinkText(editingPortalLink?.linkText ?? '');
  }, [editingPortalLink])

  useEffect(() => {
    if (link?.match(/^http(s)?:\/\//) || !link) {
      setErrorText('');
    } else {
      setErrorText('Links skal starte med http:// eller https://');
    }
  }, [link])

  const createNewLinkHandler = () => {
    const newPortalLink = {
      link,
      linkText,
      order: allPortalLinks.length + 1
    }

    setLoading(true)
    UserService.createPortalLink(newPortalLink).then((createdLink) => {
      createPortalLinkCallback(createdLink)
      NotificationService.success('Link blev oprettet');
      closeDialogHandler();
    }).catch(e => {
      NotificationService.error('Kunne ikke oprette link');
      log(e);
    }).finally(() => {
      setLoading(false);
    })
  }

  const updateNewLinkHandler = () => {
    if (!editingPortalLink?.id) return;

    const updatedPortalLink = {
      id: editingPortalLink.id,
      link,
      linkText,
      order: editingPortalLink.order
    }

    setLoading(true)
    UserService.editPortalLink(editingPortalLink.id, updatedPortalLink).then(() => {
      editPortalLinkCallback(updatedPortalLink)
      NotificationService.success('Link blev opdateret');
    }).catch(e => {
      NotificationService.error('Kunne ikke opdatere link');
      log(e);
    }).finally(() => {
      setLoading(false);
    })
  }

  const closeDialogHandler = () => {
    setLink('');
    setLinkText('');
    closeDialog();
  }

  return (
    <DialogView open={open} handleClose={closeDialogHandler}>
      <Header>
        <Typography variant='h4'>{editingPortalLink ? 'Opdater link' : 'Opret nyt link'}</Typography>
        <RoundIconButton onClick={closeDialogHandler} >
          <CloseIcon size='12px' />
        </RoundIconButton>
      </Header>
      <StyledDialogContent>
        <TextField
          label='Navn'
          fullWidth
          onChange={(e) => setLinkText(e.target.value)}
          value={linkText}
          required
        />
        <TextField
          label='URL'
          fullWidth
          helperText={errorText}
          error={!!errorText}
          onChange={(e) => setLink(e.target.value)}
          value={link}
          required
        />
      </StyledDialogContent>
      <StyledDialogFooter>
        <Button isLoading={loading} onClick={editingPortalLink ? updateNewLinkHandler : createNewLinkHandler}>{editingPortalLink ? 'Opdater link' : 'Opret link'}</Button>
      </StyledDialogFooter>
    </DialogView>
  );
};

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50vw;
  height: 150px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => props.theme.spacing(6)};
  border-bottom: 1px solid ${(props) => (props.theme.palette.grey.black5)};
  border-radius: 0 !important;
  `

const StyledDialogFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${(props) => props.theme.spacing(4)} ${(props) => props.theme.spacing(6)};
  border-top: 1px solid ${(props) => (props.theme.palette.grey.black5)};
  border-radius: 0 !important;
`

const RoundIconButton = styled(IconButton)`
  border: 1px solid ${(props) => (props.theme.palette.grey.black20)};
`


export default CreateEditPortalLinkForm;
