import { ComponentDTO, CreateAndUpdateTaskTypeDTO2, UpdateReasonCodeStatusDTO2 } from '../api/api';
import { CreateAndUpdateTaskTypeDTO2Extended } from '../models/CreateAndUpdateTaskTypeDTOExtended';
import WorkClient from './../api/workClient';

const getActiveTaskTypes = () => {
  return WorkClient.getActiveTaskTypes2();
};

const getAllTaskTypes = () => {
  return WorkClient.allTaskTypes2();
};

const addTaskType = (dto: CreateAndUpdateTaskTypeDTO2) => {
  return WorkClient.createTaskType2(dto);
};
const editTaskType = (dto: CreateAndUpdateTaskTypeDTO2Extended, id: number) => {
  return WorkClient.updateTaskType2(id, dto);
};

const getInternalTaskTypes = () => {
  return WorkClient.internalTaskTypes();
};

const getAllReasonCodes = () => {
  return WorkClient.allReasonCodes();
};

const getReasonCodes = (taskTypeId: number) => {
  return WorkClient.reasonCodes2GET(taskTypeId);
};

const createReasonCode = (taskTypeId: number, body: Array<UpdateReasonCodeStatusDTO2>) => {
  return WorkClient.reasonCodes2POST(taskTypeId, body);
};

const getComponentsForTaskType = (taskTypeId: number) => {
  return WorkClient.getComponentsForTaskType(taskTypeId);
};

const setComponentsForTaskType = (taskTypeId: number, body: ComponentDTO[]) => {
  return WorkClient.setComponentsForTaskType(taskTypeId, body);
};

const TaskTypeService = {
  getActiveTaskTypes,
  addTaskType,
  editTaskType,
  getAllTaskTypes,
  getInternalTaskTypes,
  getAllReasonCodes,
  getReasonCodes,
  createReasonCode,
  getComponentsForTaskType,
  setComponentsForTaskType
};

export default TaskTypeService;
