import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { selectTaskStatusDialogState } from '../../stateManagement/reducers/taskStatusDialogReducer';
import { selectTasks, deleteTask, clearTasks } from '../../stateManagement/reducers/taskCreationReducer';
import { DialogBody } from '../../stateManagement/reducers/confirmDialogReducer';
import { selectToast } from '../../stateManagement/reducers/notificationReducer';
import {
  getNumberOfNotApprovedRegistrations as selectNumberOfNotApprovedRegistrations,
  setNumberOfNotApprovedRegistrations
} from '../../stateManagement/reducers/timeRegistrationReducer';

import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import MinimizedTab from './MinimizedTab';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import LeftMenu from './left-menu/LeftMenu';
import Typography from '../../components/typography/Typography';
import TimeRegistrationService from '../../services/TimeRegistrationService';
import { useNavigate } from '../../hooks/useNavigate';
import UserService from '../../services/UserService';
import TaskStatusDialog from '../../components/task-status-dialog/TaskStatusDialog';
import ProfileSetupDialog from '../profile-setup-view/ProfileSetupDialog';
import NotificationService from '../../services/NotificationService';
import { selectUserProfile, setUserProfileState } from '../../stateManagement/reducers/userProfileReducer';
import Toast from '../../components/toast/Toast';
import { log } from '../../utils/logging/log';
import {
  selectShouldUpdateNumberOfInternalTasksCreated,
  setColumConfig,
  setCustomFilters,
  setDepartments,
  setOperationBaseFilter,
  setShouldUpdateNumberOfInternalTasksCreated,
  setTags,
  setTaskTypes
} from '../../stateManagement/reducers/taskListReducer';
import InternalTaskService from '../../services/InternalTaskService';
import { BaseWorkTaskTypeEnum, OrderByColumnEnum, OrderByEnum } from '../../api/api';
import addQueryParams from '../../utils/routing/add-query-params';
import { EDIT_TASK_ROUTE, NEWS_ROUTE } from '../../utils/constants';
import TaskService from '../../services/TaskService';
import { startSignalRConnect } from '../../stateManagement/middlewares/signalRMiddleware';
import NewsNotification from '../news-view/NewsNotification';
import { setNews, setShowNotification, showNotification } from '../../stateManagement/reducers/newsReducer';
import NewsService from '../../services/NewsService';

const Shell = () => {
  const tasks = useSelector(selectTasks);
  const toast = useSelector(selectToast);
  const showTaskStatusDialog = useSelector(selectTaskStatusDialogState).display;
  const unapprovedTasks = useSelector(selectNumberOfNotApprovedRegistrations);
  const shouldUpdateNumberOfInternalTasksCreated = useSelector(selectShouldUpdateNumberOfInternalTasksCreated);
  const userProfile = useSelector(selectUserProfile);
  const showNewsNotification = useSelector(showNotification);

  const { text, linkText, linkTo } = toast ?? {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getConfirmation } = useConfirmationDialog();
  const [showToast, setShowToast] = useState(false);
  const [firstUserLogin, setFirstUserLogin] = useState(false);
  const [numberOfInternalTasksCreated, setNumberOfInternalTasksCreated] = useState(0);

  const getUserFilters = useCallback(() => {
    UserService.getTaskListColumConfiguration()
      .then((res) => {
        dispatch(setColumConfig(res));
      })
      .catch((error) => {
        NotificationService.error(
          `Der er opstået en fejl i indlæsningen af kolonnevisningen på opgavelisten. Prøv at genindlæse siden.`
        );
        log(error);
      });
  }, [dispatch]);

  const getTaskListInfo = useCallback(
    (departmentId: number) => {
      TaskService.getTaskListInfo(departmentId)
        .then((res) => {
          dispatch(setTags(res.tags ?? []));
          dispatch(setDepartments(res.departments ?? []));
          dispatch(setTaskTypes(res.taskTypes ?? []));

          const baseFilters = {
            page: 1,
            pageSize: 25,
            tagId: -1,
            sortOrder: OrderByEnum.Desc,
            orderByColumn: OrderByColumnEnum.WorkTaskId,
            taskStatusFilter: res.workTaskStatusesForOperationList,
            departmentIdFilter: [departmentId]
          };

          dispatch(setOperationBaseFilter(baseFilters));
          getUserFilters();
        })
        .catch((error) => {
          NotificationService.error(`Der opstod en fejl. Prøv at genindlæse siden.`);
          log(error);
        });
    },
    [dispatch, getUserFilters]
  );

  useEffect(() => {
    NewsService.getUserNews()
      .then((response) => {
        dispatch(setNews(response));
        response.some((news) => !news.isRead) && dispatch(setShowNotification({ show: true }));
      })
      .catch((e) => {
        log(e);
      });
  }, [dispatch]);

  useEffect(() => {
    UserService.getTaskListCustomFilters()
      .then((res) => {
        dispatch(setCustomFilters(res));
      })
      .catch((error) => {
        log(error);
        NotificationService.error(`Kunne ikke indlæse 'Mine filtre' til opgavelisten. Prøv at genindlæse siden.`);
      });
  }, [dispatch]);

  useEffect(() => {
    UserService.getProfile()
      .then((res) => {
        if (res.firstLoad) {
          setFirstUserLogin(true);
          dispatch(setUserProfileState(res));
        } else {
          dispatch(setUserProfileState(res));
        }
        res?.department?.departmentId && getTaskListInfo(res.department.departmentId);
      })
      .catch((error) => {
        setFirstUserLogin(true);
        NotificationService.error(`Der opstod en fejl. Prøv at genindlæse siden. ${error}`);
      });
  }, [dispatch]);

  useEffect(() => {
    if (userProfile.isLoaded) {
      dispatch(startSignalRConnect());
    }
  }, [dispatch, userProfile.isLoaded]);

  useEffect(() => {
    if (toast) {
      setShowToast(true);
    }
  }, [toast]);

  useEffect(() => {
    TimeRegistrationService.getTimeRegistrationsNotApproved()
      .then((res) => dispatch(setNumberOfNotApprovedRegistrations(res)))
      .catch((err) => log(err));
  }, [dispatch]);

  const fetchNumberOfCreatedInternalTasks = useCallback(() => {
    InternalTaskService.getNumberOfInternalTaskInCreated()
      .then((res) => {
        dispatch(setShouldUpdateNumberOfInternalTasksCreated(false));
        setNumberOfInternalTasksCreated(res);
      })
      .catch((err) => log(err));
  }, [dispatch]);

  useEffect(() => {
    fetchNumberOfCreatedInternalTasks();
  }, [fetchNumberOfCreatedInternalTasks]);

  useEffect(() => {
    if (shouldUpdateNumberOfInternalTasksCreated) {
      fetchNumberOfCreatedInternalTasks();
    }
  }, [shouldUpdateNumberOfInternalTasksCreated, fetchNumberOfCreatedInternalTasks]);

  const handleCloseDialog = async (taskCreationId?: string) => {
    const confirmation = await getConfirmation(dialogBody);

    if (confirmation === 'confirm') {
      if (taskCreationId) {
        dispatch(deleteTask(taskCreationId));
      } else {
        dispatch(clearTasks());
      }
    }
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const onNewsLinkClick = () => {
    navigate('/' + NEWS_ROUTE);
    dispatch(setShowNotification({ show: false, news: undefined }));
  };

  return (
    <Container>
      <LeftMenu
        unapprovedTasks={unapprovedTasks}
        userProfileState={userProfile}
        createdInternalTasks={numberOfInternalTasksCreated}
      />

      <StyledContainer>
        <Outlet />
        <ConfirmDialog />
        {firstUserLogin && <ProfileSetupDialog onSetupFinish={() => setFirstUserLogin(false)} />}
        {showTaskStatusDialog && <TaskStatusDialog />}
        <StyledTabContainer>
          {tasks.map((t, i) => {
            return (
              <MinimizedTab
                onClickHandler={() => navigate(addQueryParams(undefined, { id: t.taskCreationId }, EDIT_TASK_ROUTE))}
                onCloseHandler={() => handleCloseDialog(t.taskCreationId)}
                key={i}
              >
                {t.taskType === undefined ? 'Unavngiven opgave (' + (i + 1) + ')' : t.taskType?.name}
              </MinimizedTab>
            );
          })}
        </StyledTabContainer>
      </StyledContainer>
      {showNewsNotification.show && (
        <NewsNotification
          type={showNewsNotification.news?.newsType}
          text={showNewsNotification.news?.title ?? 'Du har ulæste nyheder'}
          onLinkClick={onNewsLinkClick}
        />
      )}
      {showToast && toast && (
        <Toast toast={toast} onClose={() => handleCloseToast()}>
          <Typography variant="span">{text}</Typography>
          {linkText && (
            <StyledLink
              data-testid="link-text"
              onClick={() => navigate(linkTo ?? '', { state: { type: BaseWorkTaskTypeEnum.WorkTask } })}
            >
              {linkText}
            </StyledLink>
          )}
        </Toast>
      )}
    </Container>
  );
};

const dialogBody: DialogBody = {
  headerText: 'Vil du slette oprettelse af nyt arbejdskort?',
  bodyText: 'Bemærk, hvis du sletter arbejdskortet, fjernes alle indtastede værdier',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft sletning'
};

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
`;

const StyledTabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  position: fixed;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.backdrop};
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;

const StyledLink = styled.span`
  text-decoration: underline;
  color: ${(props) => props.theme.palette.functions.action.primary};
  &:hover {
    cursor: pointer;
  }
`;

export default Shell;
