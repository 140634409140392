import styled from 'styled-components';
import { TaskComponentAttributeDTO, TaskComponentDTO } from '../../../../../../api/api';
import KebabMenu from '../../../../../../components/kebab-menu/KebabMenu';
import { MenuItem } from '../../../../../../components/select/Select';
import { useConfirmationDialog } from '../../../../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../../../../stateManagement/reducers/confirmDialogReducer';
import { SectionHeader } from '../../../../../../styling/FormStyling';
import Field from './Field';

const navigateDialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText:
    'Er du sikker på at du vil slette denne sektion? Alle underligggende felter vil blive slettet, og handlingen kan ikke fortrydes.',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

interface Props extends TaskComponentDTO {
  setActiveSection: () => void;
  removeSection: () => void;
  setSection: (section: TaskComponentDTO) => void;
}

const Section = (props: Props) => {
  const { attributes, id, title, setActiveSection, removeSection, setSection } = props;
  const { getConfirmation } = useConfirmationDialog();

  const removeSectionHandler = async () => {
    if (!id) return;

    const confirmation = await getConfirmation(navigateDialogBody);

    if (confirmation === 'confirm') {
      removeSection();
    }
  };

  const addAttributeHandler = () => {
    if (!id) return;
    setActiveSection();
  };

  const setAttributes = (attribute: TaskComponentAttributeDTO) => {
    setSection({
      ...props,
      attributes: attributes?.map((a) => (a.attributeId === attribute.attributeId ? attribute : a))
    });
  };

  const removeAttribute = (attribute: TaskComponentAttributeDTO) => {
    setSection({ ...props, attributes: attributes?.filter((a) => a.attributeId !== attribute.attributeId) });
  };

  return (
    <StyledSection>
      <StyledHeaderContainer>
        <StyledSectionHeader>{title}</StyledSectionHeader>
        <KebabMenu>
          <MenuItem onClick={addAttributeHandler}>Tilføj felter</MenuItem>
          <MenuItem onClick={removeSectionHandler}>Slet sektion</MenuItem>
        </KebabMenu>
      </StyledHeaderContainer>
      {attributes?.map((attribute) => (
        <Field
          key={attribute.attributeId}
          attribute={attribute}
          setAttribute={(a) => setAttributes(a)}
          removeAttribute={() => removeAttribute(attribute)}
        />
      ))}
    </StyledSection>
  );
};

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const StyledSectionHeader = styled(SectionHeader)`
  margin-top: 10px;
`;

export default Section;
